<template lang="html">
  <div class="column">
    <h3 class="title">Informações do Cartão</h3>

    <div class="field" :class="{'has-error': $v.number.$error || $v.brand.$error}">
      <label class="label is-normal">Número do Cartão</label>
      <div class="control">
        <input
          class="input is-medium"
          :class="{'is-danger': $v.number.$error || $v.brand.$error}"
          type="number"
          v-model.trim="creditCard.number"
          @blur="findPaymentPlans(true)">
      </div>

      <span class="help is-danger" v-if="!$v.number.required">
        Não pode ficar em branco.
      </span>

      <span
        class="help is-danger"
        v-if="$v.number.required && (!$v.brand.required || !$v.number.minLength || !$v.number.maxLength)"
      >
        Número do cartão de crédito incorreto.
      </span>
    </div>

    <div class="field" :class="{'has-error': $v.date.$error}">
      <label class="label is-normal">Validade do Cartão</label>
      <div class="control">
        <input
          class="input is-medium"
          :class="{'is-danger': $v.date.$error}"
          type="text"
          v-model.trim="creditCard.date"
          @blur="$v.date.$touch()"
          placeholder="MM/YYYY"
          v-mask="'##/####'">
      </div>

      <span class="help is-danger" v-if="!$v.date.required">
        Não pode ficar em branco.
      </span>

      <span class="help is-danger" v-if="!$v.date.isDate">
        Não é uma data válida.
      </span>
    </div>

    <div class="field" :class="{'has-error': $v.cvv.$error}">
      <label class="label is-normal">Código de Segurança</label>
      <div class="control">
        <input
          class="input is-medium"
          :class="{'is-danger': $v.cvv.$error}"
          type="number"
          @blur="$v.cvv.$touch()"
          placeholder="CVV"
          v-model.trim="creditCard.cvv">
      </div>

      <span class="help is-danger" v-if="!$v.cvv.required">
        Não pode ficar em branco.
      </span>
    </div>

    <div class="field">
      <label class="label is-normal">Parcelas em</label>
      <div class="select is-medium is-fullwidth">
        <select :disabled="brandIsEmpty" v-model="cart.payment_info.installment">
          <option v-for="installment in installments" :value="installment">
            {{ getInstallmentText(installment) }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
  import { toCurrency } from 'utils/currency'
  import { isEmpty } from 'lodash'
  import { findPlans } from 'src/Utils/PagSeguro'

  export default {
    props: {
      cart: {
        type: Object,
        required: true
      },

      loadState: {
        type: Object,
        required: true
      },

      creditCard: {
        type: Object,
        required: true
      },

      $v: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        installments: [],
      };
    },

    computed: {
      brandIsEmpty() {
        return isEmpty(this.creditCard.brand)
      },
    },

    mounted() {
      this.findPaymentPlans();
    },

    methods: {
      getInstallmentText(obj) {
        return `${obj.installments}x de ${toCurrency(obj.installment_value)} = ${toCurrency(obj.amount.value)}`
      },

      findPaymentPlans(blur) {
        if (blur) {
          this.$v.number.$touch();
        }

        if (this.creditCard?.number.length != 16) {
          return;
        }

        this.loadState.isLoading = true;

        findPlans({
          payment_method: 'credit_card',
          value: this.cart.priceTotal,
          credit_card_bin: this.creditCard.number.substr(0, 6)
        }).then((res) => {
          const plans = res.data;

          const cc_plans = plans.payment_methods.credit_card;
          const brand = Object.keys(cc_plans)[0];

          this.installments = cc_plans[brand].installment_plans;
          this.creditCard.brand = brand;
        }).catch(() => {
          this.creditCard.brand = null;
          this.installments.length = 0;
        }).finally(() => {
          this.$v.brand.$touch();
          this.loadState.isLoading = false;
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .field > .label {
    font-weight: 400;
  }

  .title {
    color: #7F7F7F;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
</style>
