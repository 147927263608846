<template lang="html">
  <div class="tabs">
    <ul>
      <li :class="{'is-active': creditCard }">
        <a @click.prevent="setActive('creditCard')">
          <span>Cartão de Crédito</span>
        </a>
      </li>
      <li :class="{'is-active': pix }">
        <a @click.prevent="setActive('pix')">
          <span>PIX</span>
        </a>
      </li>
      <!--
      <li :class="{'is-active': boleto }">
        <a @click.prevent="setActive('boleto')">
          <span>Boleto</span>
        </a>
      </li>
      -->
      <!--
      <li :class="{'is-active': debitOnline }">
        <a @click.prevent="setActive('debitOnline')">
          <span>Débito Online</span>
        </a>
      </li>
      -->
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      creditCard: {
        type: Boolean,
        required: true
      },

      pix: {
        type: Boolean,
        required: true
      },

      setActive: {
        type: Function,
        required: true
      }
    }
  }
</script>

<style lang="css">
</style>
