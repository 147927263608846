<template lang="html">
  <div class="root">
    <div class="notification is-warning">
      <p>Ao finalizar a sua compra, você terá acesso à um link para gerar o boleto e efetuar o pagamento numa agência bancária, lotérica ou através do seu internet banking. 3 dias é o prazo de validade do boleto após gerado. Seu ingresso estará disponível em até 3 dias após o pagamento. Para realizar reembolso requer a criação de uma conta no PagSeguro.</p>
    </div>

    <div class="columns">
      <HolderForm
        :holder="cart.holder"
        :$v="$v.cart.holder">
      </HolderForm>

      <AddressForm
        :address="cart.address"
        :$v="$v.cart.address">
      </AddressForm>
    </div>
  </div>
</template>

<script>
  import AddressForm  from '../Forms/AddressForm'
  import HolderForm from '../Forms/HolderForm'


  export default {
    props: {
      cart: {
        type: Object,
        required: true
      },

      $v: {
        type: Object,
        required: true
      }
    },


    components: {
      AddressForm,
      HolderForm
    },
  }
</script>
