<template lang="html">
  <Loading :isLoading="loadState.isLoading" :classes="{'-loading': loadState.isLoading}">
    <div class="root">
      <div class="columns">
        <CreditCardForm
          :cart="cart"
          :creditCard="creditCard"
          :loadState="loadState"
          :$v="$v.creditCard">
        </CreditCardForm>

        <HolderForm
          :holder="cart.holder"
          :$v="$v.cart.holder">
        </HolderForm>

        <AddressForm
          :address="cart.address"
          :$v="$v.cart.address">
        </AddressForm>
      </div>
    </div>
  </Loading>
</template>

<script>
  import Loading from 'src/Shared/Loading'
  import CreditCardForm from '../Forms/CreditCardForm'
  import AddressForm  from '../Forms/AddressForm'
  import HolderForm from '../Forms/HolderForm'

  export default {
    data() {
      return {
        loadState: {
          isLoading: false,
        }
      }
    },

    props: {
      cart: {
        type: Object,
        required: true
      },

      creditCard: {
        type: Object,
        required: true
      },

      $v: {
        type: Object,
        required: true
      }
    },

    components: {
      CreditCardForm,
      AddressForm,
      HolderForm,
      Loading
    },
  }
</script>

<style lang="scss" scoped>
  .root {
    min-height: 15rem;
  }

  .field > .label {
    font-weight: 400;
  }

  .title {
    color: #7F7F7F;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }
</style>
