export default {
  type: 'credit_card',
  credit_card_token: '',
  installment: {
    amount: {
      value: 0,
    },
    interest_free: true,
    installments: 1,
  },
}
