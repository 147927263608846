<template lang="html">
  <div class="root">
    <div class="notification is-warning">
      <p>Ao finalizar a sua compra, você terá acesso à um link para você realizar o pagamento eletrônico direto na sua conta bancária. Seu ingresso estará disponível em até 1 hora após o pagamento. Para realizar reembolso requer a criação de uma conta no PagSeguro.</p>
    </div>

    <div class="columns">
      <BankForm
        :payment_info="cart.payment_info"
        :$v="$v.cart.payment_info"
        :banks="banks">
      </BankForm>

      <HolderForm
        :holder="cart.holder"
        :$v="$v.cart.holder">
      </HolderForm>

      <AddressForm
        :address="cart.address"
        :$v="$v.cart.address">
      </AddressForm>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex/dist/vuex.esm'
  import AddressForm  from '../Forms/AddressForm'
  import HolderForm from '../Forms/HolderForm'
  import BankForm from '../Forms/BankForm'


  export default {
    props: {
      cart: {
        type: Object,
        required: true
      },

      $v: {
        type: Object,
        required: true
      }
    },


    components: {
      AddressForm,
      HolderForm,
      BankForm
    },

    computed: {
      ...mapState({
        banks: state => state.paymentMethods.banks
      }),
    },
  }
</script>

<style lang="scss" scoped>
</style>
