<template lang="html">
  <div class="root">
    <div class="notification is-warning">
      <p>Ao finalizar a sua compra, você terá um Qrcode para pagamento válido por 1 hora.</p>
    </div>

    <div class="columns">
      <HolderForm
        :holder="cart.holder"
        :$v="$v.cart.holder">
      </HolderForm>
    </div>
  </div>
</template>

<script>
  import HolderForm from '../Forms/HolderForm'
  import AddressForm  from '../Forms/AddressForm'

  export default {
    props: {
      cart: {
        type: Object,
        required: true
      },

      $v: {
        type: Object,
        required: true
      }
    },

    components: {
      HolderForm,
      AddressForm
    },
  }
</script>
