<template lang="html">
  <div class="item" :class="ticketClass">
    <div class="header">
      <p class="name">
        {{ ticket.nameByLot }}
        <span v-if="ticket.description"
          class="pl-tooltip-help tooltip is-tooltip-multiline"
          :data-tooltip="ticket.description">
          <span class="pl-tooltip-text">?</span>
        </span>
      </p>

      <p class="price" v-if="cart.customerFee && ticket.hasDiscount">
        {{ ticket.priceOriginalCurrency }} - <span class="discount">{{ ticket.discountRepresentation }}</span> = {{ ticket.priceWithDiscountCurrency }} (+ {{ ticket.taxPriceCurrency }} taxa)
      </p>

      <p class="price" v-if="!cart.customerFee && ticket.hasDiscount">
        {{ ticket.priceOriginalCurrency }} - <span class="discount">{{ ticket.discountRepresentation }}</span> = {{ ticket.priceWithDiscountCurrency }}
      </p>

      <p class="price" v-if="cart.customerFee && !ticket.hasDiscount && !ticket.isFree">
        {{ ticket.priceOriginalCurrency }} (+ {{ ticket.taxPriceCurrency }} taxa)
      </p>

      <p class="price" v-if="!cart.customerFee && !ticket.hasDiscount">
        {{ ticket.priceOriginalCurrency }}
      </p>

      <p class="price" v-if="ticket.isFree">
        {{ ticket.priceOriginalCurrency }}
      </p>

      <p class="installments">
        {{ currentInstallments() }}
      </p>
    </div>

    <div v-if="ticket.isSoldout" class="actions">Esgotado</div>

    <modal
      title="Selecione o Local"
      v-if="selEventMapVisible"
      :isVisible="selEventMapVisible"
      :closeModal="closeSelEventMap"
      :saveButton="saveSelEventMap"
      xsMinHeight="94vh"
      lgMinHeight="94vh"
      saveButtonText="Salvar"
      cancelButtonText="Cancelar"
    >
        <sel-event-map
          :event_map_json="event_map"
          :ticket="ticket"
          ref="selEventMapRef"/>
    </modal>

    <div v-if="!ticket.isSoldout" class="actions">
      <span @click="removeCartTicket(ticket)" class="action cart">
        <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100">
          <path d="M47 47l-5 0 -22 0c-2,0 -3,1 -3,3 0,2 1,3 3,3l22 0 5 0 6 0 4 0 23 0c2,0 3,-1 3,-3 0,-2 -1,-3 -3,-3l-21 0 -6 0 -6 0 0 0 0 0z" display="inline"/>
          <path d="M50 0c-28,0 -50,22 -50,50 0,28 22,50 50,50 28,0 50,-22 50,-50 0,-28 -22,-50 -50,-50zm0 7c24,0 43,19 43,43 0,24 -19,43 -43,43 -24,0 -43,-19 -43,-43 0,-24 19,-43 43,-43z" display="inline"/>
        </svg>
      </span>

      <span class="quant">
        {{ amountByTicket(ticket) }}
      </span>

      <span @click="addTicket(ticket)" class="action cart">
        <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100">
          <path d="M47 47l-5 0 -22 0c-2,0 -3,1 -3,3 0,2 1,3 3,3l22 0 5 0 6 0 4 0 23 0c2,0 3,-1 3,-3 0,-2 -1,-3 -3,-3l-21 0 -6 0 -6 0 0 0 0 0z" display="inline"/>
          <path d="M50 0c-28,0 -50,22 -50,50 0,28 22,50 50,50 28,0 50,-22 50,-50 0,-28 -22,-50 -50,-50zm0 7c24,0 43,19 43,43 0,24 -19,43 -43,43 -24,0 -43,-19 -43,-43 0,-24 19,-43 43,-43z" display="inline"/>
          <path d="M47 53l0 5 0 22c0,2 1,3 3,3 2,0 3,-1 3,-3l0 -22 0 -5 0 -6 0 -4 0 -23c0,-2 -1,-3 -3,-3 -2,0 -3,1 -3,3l0 21 0 6 0 6 0 0 0 0z" display="inline"/>
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex/dist/vuex.esm'
  import { toCurrency } from 'utils/currency'
  import { cloneDeep } from 'lodash'
  import Modal from 'components/Modal'
  import SelEventMap from './SelEventMap'

  export default {
    props: {
      ticket: {
        type: Object,
        required: true
      },
      event_map: {
        type: String,
        required: false
      }
    },

    data() {
      return {
        selEventMapVisible: false,
      }
    },

    components: {
      Modal,
      SelEventMap,
    },

    computed: {
      ...mapGetters({
        cart: 'currentCart',
        amountByTicket: 'amountByTicket',
        taxByTicket: 'taxByTicket',
        promotion: 'currentPromotion',
      }),

      ticketClass: function () {
        return `-${this.ticket.status}`
      },
    },

    methods: {
      ...mapActions([
        'setMessage',
        'addCartTicket',
        'removeCartTicket',
      ]),

      addTicket(ticket, force) {
        console.log("addTicket", cloneDeep(ticket) , this.amountByTicket(ticket) );

        if (ticket.event_locations?.length > 0 && !force) {
          this.selEventMapVisible = true;
          return;
        }

        if (this.cart.hasReachedMaxPurchase) {
          this.setMessage({
            type: 'warning',
            message: this.hasReachedMaxPurchaseMessage()
          })
        } else {
          this.addCartTicket(ticket)

          if (window.fbq) {
            window.fbq('track', 'AddToCart', [{id: ticket.id, quantity: this.amountByTicket(ticket)}])
          }
        }
      },

      saveSelEventMap() {
        const selEventMap = this.$refs.selEventMapRef;
        if (!selEventMap.location) {
          alert('Não foi selecionado nenhum local');
          return;
        }
        const ticket = this.ticket;
        ticket.eventLocationId = selEventMap.location.id;
        this.addTicket(ticket, true);
        this.selEventMapVisible = false;
      },
      closeSelEventMap() {
        const selEventMap = this.$refs.selEventMapRef;
        selEventMap.location = null;
        selEventMap.locationObj = null;
        this.selEventMapVisible = false;
      },

      hasReachedMaxPurchaseMessage() {
        if (this.promotion) {
          return `Ops! Você não pode comprar mais de ${this.cart.maxPurchase} ingressos para este código promocional.`
        } else {
          return `Ops! Você não pode comprar mais de ${this.cart.maxPurchase} ingressos.`
        }
      },

      currentInstallments() {
        let ticket = this.ticket;

        if (ticket.installment_amount) {
          return `Em até ${ticket.installment_amount}x de ${toCurrency(ticket.installment_price)} `
        } else {
          return '';
        }
      }

    }
  }
</script>

<style lang="scss" scoped>
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    &.-soldout {
      opacity: .5;
    }
  }

  .header {
    > .name {
      font-weight: 500;
    }

    > .price > span {
      color: hsl(145, 63%, 49%);
      font-weight: 500;
    }

    > .installments {
      color: #0bad4f;
      font-weight: 500;
    }
  }

  .actions {
    display: flex;

    > .quant {
      font-weight: bold;
      margin: 0 .5rem;
    }

    > .action.cart {
      cursor: pointer;
      display: block;
      user-select: none;
    }

    > .action.cart > .icon {
      fill: hsl(214, 72%, 54%);
    }
  }
</style>
