import { http } from 'plugins/http'

const PROD_KEY = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0URRQtCF5ob5KSYc9ODZ0ppPFlMwqI0ZWVyHWyDwEB/HdOtDSildRw3KWyax9r7bihGBkEU8YhF+26WcO4UH/er6WXph/Pwue5E2o+H3gsNELYZXmF12hKm3xT3KAkVzefKhOnrw6A7ixCZrr8g8HgyuP9MwUzQbtAtVZoM9map7/utFQkls8WWJtrJauSnVnFgkUbN6cVevzjTgwgDydW2DljBFbcN9/Ulc80gvQ8xWgiUGJP/1jLTF/7Jz0yUkBes9cVLWCr2dVMgg/GxFLivrhPdmjLYQj+fqQt47IP2VNyVyX+IcROLw84xC7AdFH6rfBdvg/JBOx+ST4Lc7+wIDAQAB';
const DEV_KEY = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr+ZqgD892U9/HXsa7XqBZUayPquAfh9xx4iwUbTSUAvTlmiXFQNTp0Bvt/5vK2FhMj39qSv1zi2OuBjvW38q1E374nzx6NNBL5JosV0+SDINTlCG0cmigHuBOyWzYmjgca+mtQu4WczCaApNaSuVqgb8u7Bd9GCOL4YJotvV5+81frlSwQXralhwRzGhj/A57CGPgGKiuPT+AOGmykIGEZsSD9RKkyoKIoc0OS8CPIzdBOtTQCIwrLn2FxI83Clcg55W8gkFSOS6rWNbG5qFZWMll6yl02HtunalHmUlRUL66YeGXdMDC2PuRcmZbGO5a/2tbVppW6mfSWG3NPRpgwIDAQAB';
const publicKey = process.env.NODE_ENV == 'production' ? PROD_KEY : DEV_KEY;

export function createCardToken(creditCard, holder) {
  return new Promise((resolve, reject) => {
    const card = PagSeguro.encryptCard({
      publicKey: publicKey,
      holder: holder.name,
      number: creditCard.number,
      securityCode: creditCard.cvv,
      expMonth: getMonth(creditCard.date),
      expYear: getYear(creditCard.date)
    });
    return card.hasErrors ? reject(card.errors)
                          : resolve(card.encryptedCard);
  });
}

export function findPlans(params) {
  return http.get(`/spa/payment-plans`, { params: params });
}

function getMonth(date) {
  return date.split('/')[0]
}

function getYear(date) {
  return date.split('/')[1]
}
